import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html'
})
export class MapComponent implements OnInit {

  @Input() configMap: any = {};

  constructor() { }

  ngOnInit() {
  }

}
