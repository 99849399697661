import { Component, OnInit } from '@angular/core';
import { MfconfigService } from '../../../services/mfconfig.service';
import {FireserviceService} from '../../../services/fireservice.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  showMenuMovil: boolean;
  fireServ;
  images;

  public mFConfig: any = '';
  preloader = false;
  constructor( private MfConfig: MfconfigService, private fireService: FireserviceService) {
    this.showMenuMovil = false;
    this.fireServ = fireService;
    // this.mFConfig = MfConfig.getConfig();
    console.log(this.mFConfig);
    setTimeout(() => { this.preloader = true; }, 3000 );
  }

  async ngOnInit() {
    this.images = await this.fireServ.getCollectionFire('configuracion', 'sitio')
      .then((res) => {
        this.mFConfig = res;
        console.log(res);
      });
  }

  showMenu(e) {
    e.preventDefault();
    this.showMenuMovil = this.showMenuMovil === false;
  }

}
