import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MfconfigService} from '../../services/mfconfig.service';
import {Gallery, GalleryItem, ImageItem} from '@ngx-gallery/core';
import {Lightbox} from '@ngx-gallery/lightbox';
import {$} from 'protractor';
import {FireserviceService} from '../../services/fireservice.service';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GaleriaComponent implements OnInit {

  mFconfig: any = {};

  items: GalleryItem[];

  // @ts-ignore
  imageData = this.config.galeriaData.content;

  clsHover: string;

  images;

  modalIn: boolean;

  srcModal: string;
  fireServ;

  constructor(
    private config: MfconfigService,
    public gallery: Gallery,
    public lightbox: Lightbox,
    private fireService: FireserviceService) {
    this.mFconfig = this.config.galeriaData;
    this.modalIn = false;
    this.fireServ = fireService;
  }

  async ngOnInit() {
    this.images = await this.fireServ.getCollectionFire('gallery', 'gallery')
      .then((res) => res.imgs );
   /* this.items = this.imageData.map(item => {
      return new ImageItem({
        src: item.src, thumb: item.src
      });
    });*/
  }

  showImg(e, src) {
    e.preventDefault();
    this.modalIn = true;
    this.srcModal = src;
  }
  hideModalIn(e) {
    e.preventDefault();
    this.modalIn = false;
  }
}
