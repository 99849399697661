import { Component, OnInit } from '@angular/core';
import { MfconfigService } from '../../../services/mfconfig.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  mFconfig: any = {};

  current_Year: any;

  constructor(private config: MfconfigService) {
    this.mFconfig = this.config.getConfig();
    this.current_Year = new Date().getFullYear();
  }

  ngOnInit() {

  }

}
