import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

// import {  }
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  private urlTarget = 'https://mobiliariosfamarsa.com/api';

  constructor(private httpClient: HttpClient) {

  }

  public sendMail(request) {
  const headers = new HttpHeaders().set('Content-Type',  'application/json').set('cache-control' , 'no-cache');
    return this.httpClient.post(this.urlTarget, request, {headers: headers}).pipe(
      map((res) => {
        console.log(res);
      }));
  }
}
