import { Injectable } from '@angular/core';
import firebase from 'firebase';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FireserviceService {
  private fireService: firebase.app.App;

  constructor() {
    this.fireService = firebase.initializeApp(environment.firebaseConfig);
  }
  getCollectionFire(collection, document) {
    const db = this.fireService.firestore();
    return db.collection(collection).doc(document)
      .get().then((doc) => {
      return doc.data();
    }).catch((err) => {
      return null;
    });
  }
}
