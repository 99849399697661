// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyC9FSAK14MfdpXKrjFUYLP7Mhg_GAbBJ-Q',
    authDomain: 'mobiliariosfamarsaangular.firebaseapp.com',
    databaseURL: 'https://mobiliariosfamarsaangular.firebaseio.com',
    projectId: 'mobiliariosfamarsaangular',
    storageBucket: 'mobiliariosfamarsaangular.appspot.com',
    messagingSenderId: '966574565821',
    appId: '1:966574565821:web:26ba527da607844f8bcad4'
  },
  Instafeed: {
    // tslint:disable-next-line:max-line-length
    accessToken: 'IGQVJVVkpRNjByQmNKNFpVSUREMVlDRDBtZADBwOXoydC1RVWVhbUlDTWJNdVZAUZAkhvSThaTGgzSGJUUW4wYUNxdFhoSF9BNmZA6THJ6cGo1TTNjbWRhdE1JLXc2M0ZABeGZAOd2R1SkNBdmlrREg5dDdLSAZDZD'
    // accessToken: 'IGQVJXNHluOWl5bTR4eWE2STVxLTNHaWhqVVdHWlNBMU9oUXRGeGVZAQUFtdHIxVFZA4bi1odmJIeVlqSXZAmZA2tGX3AwR0twRk9pVnBueEJzdEJXcThrNnAwQW9pYVZA2cUtfSW5WT1I3d1VsUDhUelVjdAZDZD'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
